.contactTime {
  font-size: 12px;
}

.marketPlaceListing {
  display: flex;
  padding: 15px;
  border-radius: 5px;
  min-width: 380px;
  margin-right: 20px;
  cursor: pointer;
}

.internalWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.mobileNavbar {
  display: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 12px;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  background: white;
}

.navbarContainer {
  width: 78%;
}

.nameContainer {
  width: 20%;
}

.modal {
  font-family: "Poppins", sans-serif !important;
}

.modalImageContainer {
  width: max-content;
  margin: auto;
  position: relative;
}

.modalImageContainer img {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  object-fit: cover;
}

.imgEditIcon {
  padding: 8px;
  background: rgb(2 50 32);
  width: max-content;
  border-radius: 25px;
  position: absolute;
  top: 0;
  right: 0;
  border: 4px solid white;
}

.imgEditIcon svg {
  color: white;
}

.modalInputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
}

.modalInputs label {
  font-size: 16px;
}

.modalInputs input {
  font-size: 15px;
}

.modalInputs .inputContainer {
  display: flex;
  flex-direction: column;
  width: 49%;
  margin-bottom: 10px;
}

.modalInputs .inputContainer input {
  border-radius: 5px;
  padding: 5px 10px;
}

.modalInputs button {
  padding: 10px 30px;
  background: rgb(2 50 32);
  border-radius: 5px;
  color: white;
  margin: 5px auto 0;
}

.blogTitle {
  font-size: 15px;
  line-height: 16px;
  height: 60px;
  margin: 5px 0;
  overflow: hidden;
  align-self: start;
}

.cartQty {
  position: absolute;
  top: 0px;
  right: 10px;
  border-radius: 8px;
  background: #ff00007d;
  padding: 0px 6px;
  font-weight: 400;
  font-size: 8px;
}

.blogListingTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

@media only screen and (max-width: 750px) {
  .mobileNavbar {
    display: flex;
  }

  .cartQty {
    top: -5px;
    right: -5px;
    padding: 1px 5px;
  }

  .marketPlaceListing {
    padding: 10px;
    width: 100%;
    min-width: 358px;
  }

  .blogsImgAndTitle {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 85%;
  }

  .blogListinImageContainer {
    height: 75% !important;
  }
}
