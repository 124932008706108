.container {
  /* gap: 15px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  justify-content: space-between; */
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 5px 15px;
  border-radius: 5px;
  background: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  gap: 20px;
}

.singleItem {
  width: 160px;
  padding: 20px;
  text-align: center;
  border-radius: 6px;
  border: 3px solid lightgrey;
}
