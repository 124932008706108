.itemContainer {
  width: 210px;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 6px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background: white;
}

.itemContainer h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* line-height: X; 
  max-height: X * 2;  */
}

.productImg {
  width: 180px;
  height: 180px;
  border-radius: 6px;
  object-fit: cover;
}

.parentContainer {
  padding: 5px 15px;
  border-radius: 5px;
  background: none;
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(210px, 1fr)
  ); /* Adjusts columns dynamically */
  gap: 20px; /* Maintains consistent gap between tiles */
  margin: 20px;
}

.notAuthorized {
  width: 85%;
  margin: auto;
  padding: 30px 10px;
  text-align: center;
  /* background: white; */
  border: 6px;
}

.callBtn {
  display: flex;
  justify-content: space-around;
  width: 160px;
  background: darkgreen;
  border-radius: 25px;
  padding: 10px 40px;
  align-items: center;
  color: white;
  font-weight: 400;
  margin: 10px auto;
}

@media only screen and (max-width: 750px) {
  /* .parentContainer {
    margin: 5px;
    gap: 5px;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    padding: 5px;
  } */
  .parentContainer {
    display: flex;
    flex-wrap: wrap; /* Wrap items to the next line if needed */
    justify-content: center; /* Center items with equal margins */
    gap: 25px; /* Space between items */
    padding: 5px;
    margin: 20px auto 115px;
  }

  .parentContainer > * {
    flex: 1 1 10rem; /* Ensure each item has a min size of 10rem */
    max-width: 11rem; /* Prevent items from growing too large */
  }

  .itemContainer {
    width: 180px;
    padding: 10px;
  }

  .productImg {
    width: 160px;
    height: 160px;
  }
}

@media only screen and (max-width: 380px) {
  .parentContainer {
    gap: 5px;
  }
}

@media only screen and (max-width: 400px) {
  .parentContainer {
    gap: 10px;
  }
}
