.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}

.icon {
  margin: auto;
  color: rgb(1, 50, 32);
  font-size: 45px;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  margin: auto;
  color: rgb(3, 191, 98);
}
