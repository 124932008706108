.itemContainer {
  margin-right: 5px;
  height: 160px;
  width: 110px;
  padding: 5px;
}

.itemContainer h6 {
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itemContainer span {
  font-size: 13px;
}

.image {
  border-radius: 4px;
}

.productContainer {
  margin-bottom: 30px;
}

@media only screen and (max-width:750px) {
  .productContainer {
    margin-bottom: 128px;
  }
}
