.container {
  padding: 10px 14px;
  width: 100%;
  position: relative;
}

.input {
  width: 100%;
  height: 35px;
  border-radius: 6px;
  padding: 20px 10px;
  border: 1.5px solid;
}

.searchIcon {
  position: absolute;
  right: 19px;
  padding: 5px;
  font-size: 30px !important;
  top: 15px;
  background: darkgreen;
  color: white;
  border-radius: 5px;
}

@media only screen and (min-width: 750px) {
  .container {
    display: none;
  }
}
