.itemName {
  font-size: 0.8rem;
  margin-top: 0.15rem;
}

.singleItem {
  max-height: 225px;
  background: white;
}

.noItemsContainer {
  width: 60%;
  margin: 100px auto;
  text-align: center;
  font-size: 1rem;
  height: fit-content;
}

@media only screen and (max-width: 750px) {
  .container {
    margin-bottom: 105px;
  }

  .categoryItemListing {
    margin: auto;
    display: flex !important;
    flex-wrap: wrap !important;
    width: 75% !important;
    margin: 10px !important;
    height: 100% !important;
    max-height: 100vh !important;
    overflow-y: scroll !important;
  }

  .singleItem {
    width: 47% !important;
  }
}
