body {
  font-family: "Poppins", sans-serif !important;
}

.login {
  background: url(../public/loginbg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 60%;
}

.select {
  -webkit-appearance: none;
  appearance: none;
}

.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  content: "▼";
  font-size: 1rem;
  top: 6px;
  right: 10px;
  position: absolute;
}

::-webkit-scrollbar {
  display: none;
}

.contactBtn {
  display: flex;
  align-items: center;
}

.ant-spin {
  position: absolute;
  top: 50%;
  left: 49%;
}

@media only screen and (max-width: 750px) {
  nav {
    display: none !important;
  }
}
