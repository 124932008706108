.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.dp {
  object-fit: cover;
  width: 150px;
  height: 150px;
  border-radius: 75px;
}

.itemContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-bottom: 10px; */
}

.itemContainer span {
  margin: 7px 0;
}

.itemContainer input,
.itemContainer select,
.itemContainer textarea {
  border-radius: 7px;
  padding: 10px 12px;
  /* background: #d5ede4; */
  /* background: #d5ede4ba; */
  background: #c5d9d2b0;
  resize: none;
}

.itemContainer button {
  background: #013220;
  border-radius: 7px;
  margin-top: 15px;
  padding: 6px 10px;
  color: white;
}
