@tailwind base;
@tailwind components;
@tailwind utilities;

@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";

body {
  font-family: "Poppins", sans-serif !important;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.box_shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.goBackAndSearch {
  display: none;
}

.navigationSearch {
  display: none;
  padding: 10px;
  border: 1.5px solid;
  width: 90%;
  margin: auto;
  border-radius: 6px;
  font-size: 13px;
}

/* .pc-price-small {
  display: none;
} */

.pc-price-large {
  display: flex;
}

.cartQuantityAndDeleteIcon {
  display: none;
  align-items: center;
  justify-content: space-around;
}

.cartQuantityAndDeleteIcon input {
  width: 3rem;
  text-align: center;
}

.cartQuantityAndDeleteIcon svg {
  color: darkred;
  font-size: 2rem;
  cursor: pointer;
}

.cartBottomItems {
  margin-bottom: 130px;
}

.ant-upload.ant-upload-select {
  width: 100%;
}

.navBtnsWrapper {
  display: flex;
  align-items: center;
  padding: 10px;
  align-self: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.navBtnsWrapper .anticon svg {
  color: darkgreen !important;
}

.navBtnsWrapper .anticon svg:disabled {
  color: rgb(74, 98, 74) !important;
}

.muiNavigationBtn {
  width: 12px;
  padding: 0px;
}

.muiNavigationBtn span {
  margin-right: 0;
}

.ant-select-single {
  height: 100%;
}

.ant-modal-content {
  background: #f5f5f5 !important;
  padding: 30px !important;
}

.ant-modal {
  width: 750px !important;
}

.marketplaceImg .ant-image-img,
.pdtDetailImage .ant-image-img {
  width: 100%;
  max-width: 400px;
  height: auto;
  max-height: 400px;
  object-fit: cover;
}

.swiper .swiper-intialized {
  margin-left: -20% !important;
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: none !important;
}

.swiper-button-prev {
  margin-left: 19% !important;
}

.backIconCustom {
  display: none;
}

.oneLineText {
  /* overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; 
  line-clamp: 1; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mySwiper {
  width: 123%;
  height: 50vh;
  margin-left: -23%;
}

.MuiDrawer-paper,
.css-mlnsir-MuiDrawer-docked .MuiDrawer-paper {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.css-10hburv-MuiTypography-root {
  font-family: unset !important;
}

/* tabar */
.tabBar {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.anticon svg {
  color: white !important;
}

.ant-modal-close-x svg {
  color: black !important;
}

@media only screen and (max-width: 750px) {
  nav {
    display: none;
  }

  .navigationSearch {
    display: flex;
  }

  .goBackAndSearch {
    display: unset;
  }

  /* .pc-price-small {
    display: flex;
  } */

  .cartQuantityAndDeleteIcon {
    display: flex;
  }

  .pc-price-large {
    display: none;
  }

  .backIconCustom {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .mySwiper {
    width: 95%;
    /* height: 20vh; */
    height: 12rem;
    margin: 0 auto;
  }

  .swiper-slide {
    width: 100% !important;
  }

  .allScreensMarginBottom {
    margin-bottom: 62px;
  }

  .navBtnsWrapper {
    position: fixed !important;
    bottom: 62px;
  }

  .swiper-button-prev {
    margin-left: auto !important;
  }
}

@media only screen and (min-width: 1025px) {
  .heroBanner {
    height: 50rem;
  }
}
