.container {
  display: flex;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-bottom: 10px;
  border-radius: 6px;
  justify-content: space-between;
  position: relative;
}

.image {
  width: 30%;
  /* align-items: center; */
  display: flex;
  padding: 10px;
}

.image img {
  height: 300px;
}

.content {
  width: 67%;
  padding: 10px;
  margin-bottom: 30px;
}

.description {
  font-size: 12px;
  height: 33px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cancelAndShowContainer {
  display: flex;
  justify-content: space-between;
}

.bottomContent {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  align-items: center;
}

.nameAndActionBtn {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.placedOnText {
  color: #c5be1c;
  margin-bottom: 5px;
}

.mappedPdtContainer {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.mappedPdtContainer img {
  height: 75px;
  margin-right: 5px;
  border-radius: 10px;
}

.mappedPdtContainer p {
  font-size: 13px;
}

.mappedPdtContainer div {
  margin-left: 5px;
}

.totalPrice {
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
}

.cancelOrderBtn {
  cursor: pointer;
  margin-right: 20px;
  border: 1px solid;
  padding: 5px 10px;
  border-radius: 8px;
  background: #b23d3d;
  color: white;
  font-weight: 500;
}

@media only screen and (max-width: 750px) {
  .image img {
    height: 100px;
    align-self: self-start;
  }

  .mappedPdtContainer img {
    height: 50px;
    width: 50px;
  }
}
