.detailsContainer {
  padding: 10px 15px;
  display: flex;
}

.recommendedContainer {
  padding: 5px 15px;
  border-radius: 5px;
  background: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  gap: 20px;
}

.itemName,
.address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actionBtnsContainer {
  display: flex;
  flex-direction: row;
  width: max-content;
}

.actionBtnsContainer a {
  width: 200px;
  justify-content: center;
}

.productDetailsContainer {
  border: 1px solid;
  border-radius: 7px;
  padding: 12px;
  margin-top: 14px;
}

.productDetailsContainer h4 {
  font-weight: 600;
}

.itemAndDetail {
  display: flex;
}

.itemAndDetail p {
  width: 100%;
  max-width: fit-content;
  min-width: max-content;
}

.itemAndDetail span {
  margin-left: 5px;
  font-weight: 500;
}

.recommendedItem {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 6px;
  padding: 1rem;
}

@media only screen and (max-width: 750px) {
  .detailsContainer {
    flex-direction: column;
  }

  .recommendedContainer {
    padding: 5px;
  }

  .actionBtnsContainer {
    flex-direction: row;
  }

  .recommendedItem {
    width: 98% !important;
    margin: auto;
  }

  .itemAndDetail {
    flex-direction: column;
  }

  .itemAndDetail span {
    margin-left: 0;
  }
}
