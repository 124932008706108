.container {
  position: relative;
  /* width: 80%; */
  max-width: 600px;
  margin: auto;
  overflow: hidden;
}

.wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  flex: 0 0 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  /* extra added */
  position: absolute;
  top: 0;
  left: 0;
}

.image {
  width: 300px;
  height: 300px;
  object-fit: cover;
  display: block;
  border-radius: 8px;
}

.btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 1rem;
  /* padding: 0.5rem; */
  padding: 0rem 0.5rem;
  cursor: pointer;
  z-index: 10;
  border-radius: 50%;
}

.btn.left {
  left: 10px;
}

.btn.right {
  right: 10px;
}

.btn:hover {
  background: rgba(0, 0, 0, 0.7);
}

.show {
  opacity: 1;
  position: relative;
}
