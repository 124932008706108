.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  padding: 20px;
}

.head .search_container__cTpBd {
  width: 300px;
}

.firstBlogContainer {
  margin: 0 20px 20px;
  border-radius: 6px;
  background-color: #d3d3d38f;
}

.contentWrapper {
  padding: 15px;
}

.contentWrapper img {
  border-radius: 5px;
  margin: 0 auto 10px;
}

.contentWrapper h4 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.readMoreBtn {
  background: #004800;
  padding: 7px;
  border-radius: 5px;
  width: 100%;
  display: flex;
  /* text-align: center; */
  justify-content: center;
  color: white;
  font-size: 12px;
}

.smallTilesContainer {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  gap: 1.25rem;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.smallTile {
  border-width: 1px;
  border-radius: 0.375rem;
  padding: 10px;
  background: white;
}

.smallTile .title {
  font-size: 13px;
  line-height: 14px;
  height: 40px;
  margin: 5px 0;
  overflow: hidden;
}

.smallTile img {
  height: 150px;
}

@media only screen and (max-width: 750px) {
  .head {
    align-items: self-start;
    flex-direction: column;
  }
  .smallTilesContainer {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
