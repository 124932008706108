.headContainer {
  flex-direction: column;
  align-items: flex-start;
  background-color: #f5f5f5;
  padding: 12px;
}

@media only screen and (max-width: 750px) {
  .productsListContainer {
    margin: auto;
  }
}
