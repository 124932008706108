.selectionContainer {
  margin: 10px 30px;
}

.sectionSelection {
  display: flex;
  justify-content: space-between;
}

.sectionSelection .heading {
  width: 50%;
  text-align: center;
  cursor: pointer;
}

.sectionSelection .heading:first-child {
  margin: 10px 0 10px 10px;
}

.sectionSelection .heading:last-child {
  margin: 10px 10px 10px 0;
}

.sectionSelection .heading .span {
  margin-top: 10px;
  width: 100%;
  display: flex;
}

.item {
  border-radius: 5px;
  margin-bottom: 5px;
  background: white;
}

.parentContainer {
  padding: 5px 15px;
  border-radius: 5px;
  background: none;
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr)); */
  grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
  gap: 20px;
}

.addBtn {
  border: 1.5px solid darkgreen;
  padding: 7px;
  border-radius: 5px;
  width: 95%;
  margin: 0px auto 20px;
  display: flex;
  justify-content: center;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  background: #d3d3d369;
  padding: 30px;
}

.itemContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.itemContainer span {
  margin: 7px 0;
}

.itemContainer input,
.itemContainer select,
.itemContainer textarea {
  border-radius: 7px;
  padding: 10px 12px;
  background: white;
  resize: none;
}

.itemContainer button {
  background: #013220;
  border-radius: 7px;
  margin-top: 15px;
  padding: 6px 10px;
  color: white;
}

.twoItemsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.hidePriceContainer {
  display: flex;
  align-items: center;
}

.hidePriceContainer button {
  margin: auto 0 auto 10px;
  border-radius: 30px;
  background: grey;
  padding: 0;
}

.uploadBtn {
  width: 100% !important;
  margin-top: 0 !important;
  border: 1px dashed darkgreen !important;
  background: white !important;
  color: darkgreen !important;
  padding: 20px !important;
}

.hidePriceContainer button.ant-switch-checked {
  background: rgb(66, 114, 66) !important;
}

.itemName {
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.pageTitle {
  font-weight: 600;
  font-size: 20px;
  color: #b41111;
}

.marketplaceItemsContainer {
  margin-bottom: 15px;
  background: white;
}

@media only screen and (max-width: 600px) {
  .parentContainer {
    margin: 0;
    gap: 5px;
    /* grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr)); */
    padding: 0;
  }

  .singleItem {
    margin: auto;
    padding: 9px 7px;
  }

  .container {
    padding: 8px 15px 15px;
  }

  .marketplaceItemsContainer {
    margin-bottom: 114px;
  }
}
