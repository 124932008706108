.quickLinks {
  /* margin-top: 12px; */
  display: flex;
  justify-content: space-around;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
  width: 98%;
  margin: 12px auto 0;
  border-radius: 7px;
  font-weight: 600;
}

.quickLinks a:hover {
  cursor: pointer;
  color: rgb(58, 143, 58);
}

@media only screen and (max-width: 750px) {
  .quickLinks {
    display: none;
  }
}
