.numberInput {
  position: relative;
}

.sendOtpTick {
  position: absolute;
  right: 0;
}

@media only screen and (max-width: 770px) {
  .otpContainer {
    /* margin-left: 20px;
    margin-bottom: 20px; */
    max-width: 90%;
    position: unset !important;
    margin: auto;
  }

  .numberInput {
    max-width: 80%;
  }

  .otpInput {
    min-width: 200px;
  }
}
